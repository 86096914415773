import React from "react"
import { css } from "@emotion/core"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
// import { Link } from "gatsby"
import { Box, Flex, Button, Image } from "@rebass/emotion"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import "@reach/dialog/styles.css"
import ReactPlayer from "react-player"
import { FaTimes, FaPlay } from "react-icons/fa"
// import verticalDecorator from "../images/decorators/vertical.png"

import {
  Layout,
  Container,
  SEO,
  Text,
  Section,
  Heading,
  DecoratedHeading,
  Hero,
} from "../components"

export const pageQuery = graphql`
  query NewsQuery {
    prismicNews {
      data {
        hero {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 4160) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        video_updates {
          link {
            url
          }
          thumbnail {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 306) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title {
            text
          }
        }
        upcoming_events {
          date {
            text
          }
          title {
            text
          }
        }
      }
    }
  }
`

export default function News({ data }) {
  let [modalOpen, setModalOpen] = React.useState(false)
  let [currentVideoUrl, setVideoUrl] = React.useState("")
  const chronologicalVideoUpdates = [
    ...data.prismicNews.data.video_updates,
  ].reverse()

  return (
    <Layout>
      <SEO title="News" keywords={[`news`]} />
      <Hero fluid={data.prismicNews.data.hero.localFile.childImageSharp.fluid}>
        <Container>
          <DecoratedHeading type="cornerWhite">News</DecoratedHeading>
        </Container>
      </Hero>
      <Container>
        <Section>
          <Text as="div" textAlign="center">
            <DecoratedHeading>Update: ELI is online and also back on the ground in Nepal!</DecoratedHeading>
          </Text>
          <Text>{`ELI is engaged on the ground in Nepal providing teacher professional development in partnership with educators from Nepal. Teacher professional learning continues to be supported in Egypt and Haiti in conjunction with educators from both contexts. Also check out the ELI online, open courses offered in partnership with D2L: https://opencoursesstore.d2l.com/`}</Text>
        </Section>
        <Section>
          <Text as="div" textAlign="center">
            <DecoratedHeading h3>Video Updates</DecoratedHeading>
          </Text>
          <Flex flexWrap="wrap">
            {chronologicalVideoUpdates.map((update, i) => (
              <Button
                key={i}
                width={[1, 1, 1 / 3, 1 / 3]}
                px="4"
                bg="transparent"
                onClick={() => {
                  setModalOpen(true)
                  setVideoUrl(update.link.url)
                }}
                css={css`
                  cursor: pointer;
                  margin-bottom: 24px;
                  position: relative;
                `}
              >
                <Text color="black">{update.title.text}</Text>
                <div
                  css={css`
                    position: relative;
                  `}
                >
                  <GatsbyImage
                    fluid={update.thumbnail.localFile.childImageSharp.fluid}
                  />
                  <div
                    css={css`
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      background: rgba(0, 0, 0, 0.2);
                      opacity: 0;
                      transition: 0.2s ease-in-out transform;

                      &:hover {
                        opacity: 1;

                        & svg {
                          transform: translate(-50%, -50%) scale(1);
                        }
                      }
                    `}
                  >
                    <FaPlay
                      css={css`
                        font-size: 48px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transition: 0.2s ease-in-out transform;
                        transform: translate(-50%, -50%) scale(1.2);
                      `}
                    />
                  </div>
                </div>
              </Button>
            ))}
          </Flex>
          <Box>
            <div>
              <DialogOverlay
                css={css`
                  display: flex;
                  align-items: center;
                `}
                isOpen={modalOpen}
                onDismiss={() => setModalOpen(false)}
              >
                <DialogContent
                  css={css`
                    width: auto !important;
                    max-width: 90%;
                    padding: 2rem 0 0 !important;
                    position: relative;
                  `}
                >
                  <ReactPlayer
                    url={currentVideoUrl}
                    playing
                    css={css`
                      max-width: 100%;
                    `}
                  />
                  <button
                    onClick={() => setModalOpen(false)}
                    css={css`
                      position: absolute;
                      right: 0;
                      top: 0;
                      background: transparent;
                      cursor: pointer;
                      border: none;
                      padding: 6px;
                    `}
                  >
                    <FaTimes />
                  </button>
                </DialogContent>
              </DialogOverlay>
            </div>
          </Box>
        </Section>
        {/* <Section>
          <Text as="div" textAlign="center">
            <DecoratedHeading>Upcoiming Events</DecoratedHeading>
          </Text>
          <Flex flexWrap="wrap">
            {data.prismicNews.data.upcoming_events.map((event, i) => (
              <Flex
                key={i}
                width={[1, 1, 1 / 3, 1 / 3]}
                px="3"
                mb="3"
                justifyContent="space-between"
                alignItems="center"
              >
                <Image src={verticalDecorator} />
                <Flex flexDirection="column" alignItems="center" flex="1">
                  <Text>{event.date.text}</Text>
                  <Heading h3 mb="0">
                    {event.title.text}
                  </Heading>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Section> */}
      </Container>
    </Layout>
  )
}
